/* Reset */
html,
body,
div {
  border: 0;
  margin: 0;
  padding: 0;
}


/* Box-sizing border-box */
* {
  box-sizing: border-box;
}

/* Set up a default font and some padding to provide breathing room */
body {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


/*input {*/
/*  border: 1px solid #106cc8;*/
/*  font-size: 14px;*/
/*  height: 40px;*/
/*  outline: none;*/
/*  padding: 8px;*/
/*}*/

/*button:hover {*/
/*  background-color: #28739e;*/
/*}*/

.content {
  background-color: #fff;
  border: solid 1px #dbe1ec;
  border-radius: .5em;
  padding-bottom: .5rem;
}

/* remove padding from dropdown button within autocomplete that breaks styling when form-control */
p-autocomplete input.form-control ~ button {
  padding: 0 !important;
}

input.form-control:hover {
  border-color: black !important;
}

/* make placeholders lighter */
::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #929ba2 !important;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #929ba2 !important;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: #929ba2 !important;
}

.p-placeholder {
  color: #929ba2 !important;
}

/* fix for google-places-autocomplete showing up behind p-dialog */
.pac-container {
  z-index: 1010 !important;
}

.tabview > div.p-tabview > div.p-tabview-panels {
  padding: 0.571rem 0 0 0;
  border-left: none;
  border-right: none;
  border-bottom: none;
}

.inner-tabview {

}
